.footer_container {
  background-color: #430964;
  height: 500px;
  padding: 80px 80px 0px 80px;
  display: flex;
  flex-direction: column;
}
.info-links {
  flex: 0.25;
  display: "flex";
  height: "80%";
  line-height: 1.5;
  justify-content: "center";
  align-items: "center";
  color: #ffffff;
}
.contact-us-display-top {
  display: flex;
  flex: 0.6;
}
.title-display {
  display: flex;
  flex: 0.6;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.address-section {
  display: flex;
  flex: 0.4;
  justify-content: center;
  align-items: center;
}
.address-text {
  width: 402px;
  color: #ffffff;
  font-family: "inter";
  font-size: 400;
  font-size: 20px;
}
.link-container {
  display: flex;
  flex: 0.4;
}
.email-text {
  text-decoration: underline;
}
.social-links-container {
  display: flex;
  flex: 0.6;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.header-placement {
  width: 455px;
}
.job-link-container {
  display: flex;
  flex: 0.4;
  justify-content: center;
  align-items: center;
  padding-left: 30px;
}
.job-link-text {
  width: 400px;
  line-height: 3;
  color: #ffffff;
}
