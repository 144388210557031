.container-case-study {
  width: 399px;
  height: 399px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
}
.case-study-title {
  color: #ffffff;
  font-size: 32px;
  font-weight: 700;
  margin-top: 16px;
  margin-bottom: 16px;
  margin-left: 24px;
  font-family: "inter";
}
.case-staudy-paragraph {
  color: #ffffff;
  margin-bottom: 24px;
  margin-left: 24px;
  font-size: 16px;
  font-weight: 400;
  font-family: "inter";
}
.line {
  height: 4px;
  width: 40px;
  background-color: #d100c9;
  margin-left: 24px;
}
