.container {
  height: 680px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.navbar {
  background-color: #70259b;
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 40px;
  margin-right: 40px;
  position: fixed;
  top: 0;
}
.banner {
  width: 100%;
  height: 600px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  background-color: #000000;
  background-image: url("../../assets/confidence.jpg");
  background-size: cover;
  background-position: 20px;
  margin-top: 80px;
}

.banner-text {
  display: flex;
  flex: 0.5;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 80px 80px;
  flex-direction: column;
}
