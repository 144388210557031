.container-info-block {
  width: 290px;
  height: 399px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  margin-top: 54px;
}
.title-info-block {
  margin-top: 54px;
  font-size: 24px;
  font-weight: 700;
  font-family: "inter";
}
.paragraph-info-block {
  font-size: 20px;
  font-weight: 400;
  font-family: "inter";
  height: 290px;
  width: 100%;
}
