.content-container {
  display: flex;
  justify-content: center;
  align-items: center;

  width: "100%";
  background-color: #131f48;
  margin-top: 20px;
}
.info-block-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.case-study-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.brand-container {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.App-logo {
  height: 156px;
  pointer-events: none;
}
.title-banner-spec {
  max-height: 450px;
  width: 100%;
  pointer-events: none;
}
.quote-banner {
  margin-top: 14px;
  max-height: 350px;
  width: 100%;
  pointer-events: none;
}
.services-banner {
  /* height: 80%; */

  width: 100%;
  pointer-events: none;
}
.pallet-image {
  margin-top: 14px;
  max-height: 350px;
  width: 100%;
  pointer-events: none;
}
